import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/monkey/Projects/bkc-sajt/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Kratki pregled`}</h2>
    <Row mdxType="Row">
      <Column mdxType="Column">
        <p>{`BKC Gračanica čuva privatnost svih građana koji koriste ili su koristili  bilo koji vid komunikacije sa BKC, osim podataka za koje imamo saglasnost da ih objavimo javno. Svi podaci se pažljivo čuvaju i dostupni su samo zaposlenima kojima su ti podaci potrebni za obavljanje posla. Mediji preko kojih objavljujemo dostupne podatke su:`}</p>
        <ul>
          <li parentName="ul">{`Radio stanica`}</li>
          <li parentName="ul">{`Web stranica Radio Gračanice`}</li>
          <li parentName="ul">{`Web stranica BKC-a`}</li>
          <li parentName="ul">{`Facebook stranice Radio Gračanice i BKC-a`}</li>
        </ul>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      